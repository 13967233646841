<template>
    <el-form
        class="mark-venues-lost-form"
        :model="formData"
        ref="mark-venues-lost-form"
        v-loading="loading"
    >
    <div class="form-content">
        <div v-for="venue in venues" :key="venue.id" class="venue-section mb-4">
            <div class="venue-header d-flex align-items-center justify-content-between mb-3">
                <h5 class="mb-0">{{ venue.name }}</h5>
                <div class="venue-tags">
                    <el-tag v-if="venue.recommended" size="small" type="info">Recommended</el-tag>
                    <el-tag v-if="venue.siteVisit" size="small" type="info">Site Visit</el-tag>
                    <el-tag v-if="venue.quoted" size="small" type="info">Quoted</el-tag>
                </div>
            </div>

            <div class="row" v-if="formData.venues[venue.id]">
                <div class="col-md-6">
                    <base-input label="Lost Reason">
                    <el-select
                        v-model="formData.venues[venue.id].lost_reason"
                        size="mini"
                        filterable
                        placeholder="Lost Reason"
                        >
                            <el-option
                                v-for="(value, key) in lostReasons"
                                :key="key"
                                :label="value"
                                :value="value"
                            >
                            </el-option>
                        </el-select>
                    </base-input>
                </div>
                <div class="col-md-6">
                    <base-input label="Details">
                        <el-input
                            type="textarea"
                            v-model="formData.venues[venue.id].lost_details"
                            :rows="3"
                            placeholder="Enter details"
                        ></el-input>
                    </base-input>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right mt-4">
                <base-button type="secondary" @click="cancel">
                    Back
                </base-button>
                <base-button 
                    type="primary"
                    class="ml-2"
                    @click="save">
                    Confirm
                </base-button>
            </div>
        </div>
    </div>
  </el-form>
</template>

<script>
import { 
    Input,
    Tag,
    Form,
    FormItem,
    Select,
    Option
} from 'element-ui';

import { TentativeSalesService } from "@/services";

export default {
    name: 'MarkMultipleVenuesLost',
    components: {
        [Input.name]: Input,
        [Tag.name]: Tag,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Select.name]: Select,
        [Option.name]: Option
    },
    props: {
        leadEvent: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            formData: {
                venues: {}
            }
        };
    },
    computed: {
        lostReasons() {
            return this.$store.getters["venues/getLostStatuses"]();
        },
        venues() {
            let inspections = this.inspections;
            let customInspections = this.$store.getters['inspections/getCustomVenueInspectionsByLeadEventId'](this.leadEvent.id);
            
            inspections = inspections.filter ? inspections.filter(data => data.sale_status != 'won' && data.sale_status != 'lost') : [];
            customInspections = customInspections.filter ? customInspections.filter(data => data.sale_status != 'won' && data.sale_status != 'lost'): [];
            
            let venueEngagements = {};
            let venues = [];

            // Setup recommended flag for the venue.
            this.recommendations.forEach(recommendation => {
                if ( ! this.venuesMarkedAsBooked.includes(recommendation.id)) {
                    venueEngagements[recommendation.id] = {
                        quoted: recommendation.isQuoteUploaded || false,
                        recommended: true
                    };
                }
            });

            // Setup quoted flag for the venue.
            this.enquiries.forEach(enquiry => {
                if (enquiry.venue &&  ! this.venuesMarkedAsBooked.includes(enquiry.venue_id)) {
                    // Ensure venueEngagements[enquiry.venue_id] is initialized
                    if (!venueEngagements[enquiry.venue_id]) {
                        venueEngagements[enquiry.venue_id] = {};
                    }

                    // Mark as quoted if applicable
                    if (enquiry.isQuoteUploaded) {
                        venueEngagements[enquiry.venue_id].quoted = true;
                    }
                }
            });

            // Add inspection venues to lost input.
            inspections.forEach(inspection => {
                if (! this.venuesMarkedAsBooked.includes(inspection.venue_id)) {
                    venues.push({
                        id: inspection.venue_id,
                        name: inspection.venue_name,
                        siteVisit: true,
                        quoted: venueEngagements[inspection.venue_id]?.quoted,
                        recommended: venueEngagements[inspection.venue_id]?.recommended,
                        venueType:'venue'
                    });
                }
            });

            // Add custom inspection venues to lost input.
            customInspections.forEach(inspection => {
                venues.push({
                    id: inspection.custom_venue_id,
                    name: inspection.venue_name,
                    siteVisit: true,
                    venueType:'custom'
                });
            });

            return venues;
        },
        inspections() {
            return this.$store.getters["inspections/getInspectionsByLeadEventId"](
                this.leadEvent.id
            );
        },
        recommendations() {
            return this.$store.getters["recommendations/getRecommendationsByLeadEventId"](
                this.leadEvent.id
            );
        },
        enquiries() {
            return this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](
                this.leadEvent.id
            );
        },
        bookingsWithoutInspection() {
            return this.$store.getters["inspections/getBookingsByLeadEventId"](
                this.leadEvent.id
            );
        },
        venuesMarkedAsBooked() {
            let inspectionsWon = this.inspections.filter ? this.inspections.filter(data => data.sale_status == 'won') : [];
            let bookingsWithoutInspectionsWon = this.bookingsWithoutInspection.filter ? this.bookingsWithoutInspection.filter(data => data.sale_status == 'won') : [];

            let venues = [];

            inspectionsWon.forEach(inspection => {
                venues.push(inspection.venue_id);
            });

            bookingsWithoutInspectionsWon.forEach(booking => {
                venues.push(booking.venue_id);
            });

            return venues;
        }
    },
    async created() {
        this.loading = true;
        await this.$store.dispatch('inspections/getInspections', this.leadEvent.id);
        await this.$store.dispatch('inspections/getCustomVenueInspections', this.leadEvent.id);
        await this.$store.dispatch("venues/getVenueLostStatus");
        // Initialize formData.venues with empty data for each venue
        if (this.venues.length === 0) {
            this.loading = false;
            this.$emit('cancel');
            return;
        }
        this.venues.forEach(venue => {
            this.$set(this.formData.venues, venue.id, {
                venueType: venue.venueType,
                lost_reason: '',
                lost_details: ''
            });
        });
        this.loading = false;
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        save() {
            this.loading = true;

            TentativeSalesService.markMultipleVenuesLost(this.leadEvent.id, this.formData.venues)
                .then(() => {
                    this.loading = false;
                    this.$emit('submit');
                })
                .catch(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style>
.mark-venues-lost-form .form-content {
    padding: 20px;
}
.mark-venues-lost-form .venue-section {
    padding: 15px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
}
.mark-venues-lost-form .venue-tags .el-tag {
    margin-left: 8px;
}
.mark-venues-lost-form .el-tag--success {
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    color: #67c23a;
}
.mark-venues-lost-form .el-tag--warning {
    background-color: #fdf6ec;
    border-color: #faecd8;
    color: #e6a23c;
}
.mark-venues-lost-form .el-tag--info {
    background-color: #f4f4f5;
    border-color: #e9e9eb;
    color: #909399;
}
</style>
